#gallery {
  background-color: $complementary_color_dark;
  color: $secondary_color;
  padding-top: 2rem;
  padding-bottom: 4rem;

  .slideshow_img img {
    width: 100%;
    max-height: 600px;


    object-fit: cover;
    object-position: center;
  }
}
