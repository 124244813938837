.accommodations_wrapper {
  color: $complementary_color_dark;

  .accommodations {
    display: flex;
    flex-direction: column;
    width: 100%;

    img {
      height: calc(var(--app-height) - 6rem);

      @include size_S {
        height: calc(var(--app-height) - 7.75rem);
      }

      object-fit: cover;
      border-radius: 0;
    }

    &_img{
      display: flex;
      background-color: $complementary_color_dark;
      width: 100%;
    }

    &_container {
      h1 {
        text-align: start;
      }

      &_info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 4rem 0;

        @include size_XL {
          grid-template-columns: 1fr;
          gap: 4rem;
        }

        @include size_XS {
          padding: 2rem 0;
        }

        gap: 2rem;

        &_text {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          &_button {
            justify-content: flex-end;
            font-weight: 400;
            width: fit-content;

            div {
              display: flex;
              align-items: center;
              background-color: $complementary_color_dark;
              padding: 10px 20px;
              border-radius: 5px;

              color: $secondary_color;
            }
          }
        }

        &_details {
          display: grid;
          grid-template-columns: 1fr 1fr;

          @include size_XS {
            grid-template-columns: 1fr;
            gap: 0;
          }

          gap: 2rem;

          p {
            color: $complementary_color_light;
            letter-spacing: 4px;
            margin-top: 0;
            font-weight: 600;
          }

          ul {
            margin-bottom: 3rem;

            li::marker {
              color: $complementary_color_light;
            }
          }
        }
      }
    }
  }
}
