.slideshow {
  background-color: $complementary_color_dark;
  color: $secondary_color;

  &_img {
    img {
      width: 100%;
    }
  }

  &_info {

    &_default {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 4rem;

      &_text {
        font-size: 1.25rem;
      }

      &_buttons {
        display: flex;
        gap: 2rem;

        div {
          display: flex;
          cursor: pointer;
          transition: $transition;
        }
      }
    }

    &_name {
      display: flex;
      align-items: center;

      h2 {
        font-size: 1.5rem;
        margin: 0;

        @include size_S {
          padding-top: 1rem;
        }
      }
    }
  }

  .slick-slide {
    div {
      display: flex;
    }
  }

  .with_name{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    @include size_S{
      display: block;
    }
  }
}
